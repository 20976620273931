import React from "react";

const GoogleMap = ({ extraClassName }) => {
  return (
    <div className={`google-map__${extraClassName}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.879561107948!2d-111.02634033161655!3d29.079703752963493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce83c1e51a63bb%3A0x987250ebf5ce8f5b!2sBlvd.%20Antonio%20Quiroga%20150%2C%20Villa%20Sauces%2C%2083220%20Hermosillo%2C%20Son.!5e0!3m2!1ses-419!2smx!4v1693936745475!5m2!1ses-419!2smx"
        className={`map__${extraClassName}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;